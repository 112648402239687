import React from 'react';
import { AboutUs, RefundAndCancellation } from '@medtrail/shared-ui';
import Header from '../../components/header';
import { ROUTE_FLAG, WEBSITE } from '../../constants';


const refundAndCancellation = () => {

  return (
    <React.Fragment>
      <div className='headerContainer'>
        <Header active={null} routeFlag={ROUTE_FLAG.paymentPolicyPage}/>
      </div>
      <RefundAndCancellation website={WEBSITE}/>
    </React.Fragment>
  );

};

export default refundAndCancellation;
